import {isEmpty} from "@/services/CommonService";

class Menu {

    constructor(value, submenu) {
        this.value = value
        this.submenu = submenu
    }

    default() {
        return Object.values(this.submenu)[0].value
    }

    hasSubmenu(questionedSubmenu) {
        return this.submenu.some(sub => sub.value === questionedSubmenu)
    }

}

class SubMenu {

    constructor(value, url) {
        this.value = value
        this.url = isEmpty(url) ? value : url
    }

    equals(submenuValue) {
        return submenuValue === this.value
    }

}

const SUBMENU = Object.freeze({
    MINE: new SubMenu("mine"),
    MEETINGS: new SubMenu("meetings"),
    MINISTRY: new SubMenu("ministry"),
    REPORT: new SubMenu("report"),
    PUBLISHERS: new SubMenu("publishers"),
    CONG: new SubMenu("cong"),
    USERS: new SubMenu("users"),
    PROFILE: new SubMenu("profile"),
    CARTS: new SubMenu("carts"),
})

const MENU = Object.freeze({
    MINE: new Menu("mine", [
        SUBMENU.MINE
    ]),
    MEETING: new Menu("meeting", [
        SUBMENU.MEETINGS
    ]),
    MINISTRY: new Menu("ministry", [
        SUBMENU.MINISTRY,
        SUBMENU.REPORT,
        SUBMENU.PUBLISHERS,
        SUBMENU.CARTS,
    ]),
    ADMIN: new Menu("admin", [
        SUBMENU.USERS,
        SUBMENU.CONG,
        SUBMENU.PROFILE,
    ]),
})

export default ({
    ...MENU,
    SUBMENU
})