import {createRouter, createWebHistory} from 'vue-router'
import {nextTick} from 'vue';

import CommonElem from "@/services/CommonElem";
import LoginService from "@/services/LoginService";
import Menu from "@/services/enums/Menu";
import {isNotEmpty, redirect} from "@/services/CommonService";
import OneTimeLoginCtrl from "@/services/controllers/OneTimeLoginCtrl";

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import("@/views/login/Login"),
    /*meta: {
      title: "Moje Shro",
      icon:"/lock.png"
    }*/
  }, {
    name: 'users',
    component: () => import("@/views/users/Users_"),
    path: '/users',
  }, {
    name: 'mine',
    component: () => import("@/views/mine/Mine_"),
    path: '/mine',
  }, {
    name: 'meetings',
    component: () => import("@/views/meetings/Board_.vue"),
    path: '/meetings',
  }, {
    name: 'cong',
    component: () => import("@/views/cong/CongPg"),
    path: '/cong',
  }, {
    name: 'ministry',
    component: () => import("@/views/ministry/Ministry_.vue"),
    path: '/ministry',
  }, {
    name: 'profile',
    component: () => import("@/views/profile/Profile"),
    path: '/profile',
  }, {
    name: 'tempLogin',
    component: () => import("@/views/login/TempLogin"),
    path: '/tempLogin',
  }, {
    name: 'report',
    component: () => import("@/views/report/self/SelfReport_"),
    path: '/report',
  }, {
    name: Menu.SUBMENU.PUBLISHERS.value,
    component: () => import("@/views/report/publishers/Publishers_"),
    path: '/publishers',
  }, {
  }, {
    name: Menu.SUBMENU.CARTS.value,
    component: () => import("@/views/carts/Carts_"),
    path: '/carts',
  }, {
    path: '/index.htmlx', // or '/index.html'
    beforeEnter: (to, from, next) => {
      next('/')
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(async (to, from) => {
  CommonElem.state.loading = false
  CommonElem.state.dark = false

  const token = to.query[OneTimeLoginCtrl.TOKEN_QUERY]

  if (isNotEmpty(token)) {
    const success = await OneTimeLoginCtrl.login(token)
  }

  if (to.path === "/" || to.path === "" || to.path.startsWith("/login") || to.path.startsWith("/tempLogin")) {
    if (LoginService.isLogged()) {
      return { name: 'mine' }
    } else {
      return true
    }
  } else if (LoginService.isLogged()) {
    return true
  } else {
    return { name: 'Login'}
  }
})

router.afterEach((to, from) => {
  nextTick(() => {
    document.title = "myJW"//to.meta.title || DEFAULT_TITLE;
  });
});

router.onError(error =>{
  if (/loading chunk \d* failed./i.test(error.message) && navigator.onLine) {
    caches.keys().then(function(names) {
      for (let name of names)
        caches.delete(name);
    });
    window.location.reload()
  }
}); // endof onError

export default router
